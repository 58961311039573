/* eslint-disable prettier/prettier */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as CertifyIcon } from "modules/theme/icons/icon-certified.svg";
import { ReactComponent as CertifiedIcon } from "modules/theme/icons/icon-certified-green.svg";
import Button, { ButtonSize, ButtonStyle } from "components/Button";
import { convertDate } from "modules/utils/convertDate";
import { storeCompanyId } from "modules/utils/storeCompanyId";
import ArchiveButton from "../ArchiveButton";
import {DropOutMenu, DropOutItem } from "components/DropOutMenu";

import { useModal } from "modules/hooks/modal";
import { Assessment, AssessmentFormat, AssessmentState } from "../../types";

import styles from "../../styles.module.scss";
import LockedActions from "../LockedActions";
import ProgressBar from "components/ProgressBar";

type PanelRowItilProps = {
  assessment: Assessment;
  organizationId: number;
  handleAssessmentProfile: (id: number, type?: string) => void;
  handleIsLocked: (id: number) => Promise<boolean>;
  isUnlocked: (id: number) => Promise<boolean>;
  locked: boolean;
  onChangeArchive: (theAssessment: Assessment, type: AssessmentFormat) => void;
};

const PanelRowItil: React.FunctionComponent<PanelRowItilProps> = ({
  assessment,
  organizationId,
  handleAssessmentProfile,
  locked,
  handleIsLocked,
  isUnlocked,
  onChangeArchive,
}) => {
  const [isCerfified, setIsCertified] = useState(false);
  const [isLocking, setIsLocking] = useState(false);
  const history = useHistory();
  const certifyConfirmation = useModal();

  const handleCertifyAssessment = async (assessmentId: number) => {
    try {
      const response = await certifyConfirmation({
        type: "confirm",
        catchOnCancel: true,
        title: "Are you sure you would like independent validation for this assessment?",
        description:
          "A request for independent validation has been sent out and you will receive the response shortly.",
        assessmentId: assessmentId,
        buttonLabel: "Request validation ",
        assessmentType: "ITIL",
        hasCloseButton: true,
      }).then(() => true);

      if (response) setIsCertified(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };

  const capabilityProgressNumber = assessment.capabilities.filter((c) => {
    return c.answeredQuestions > 0 && c.answeredQuestions < c.totalQuestions;
  }).length;
  const capabilityTotalNumber = assessment.capabilities.length;
  const capabilityCompletedNumber = assessment.capabilities.filter((c) => {
    return c.answeredQuestions === c.totalQuestions;
  }).length;
  const maturityCompleteNumber = assessment.maturities.filter((m) => {
    return m.answeredQuestions === m.totalQuestions;
  }).length;
  const maturityProgressNumber = assessment.maturities.filter((m) => {
    return m.answeredQuestions > 0 && m.answeredQuestions < m.totalQuestions;
  }).length;

  const maturityTotalNumber = 5;

  const handleAssessmentName = () => {
    return assessment.name ? assessment.name : "Not provided";
  };

  const viewSummaryButtonProps = {
    onClick: async () => {
      setIsLocking(true)
      const response = await handleIsLocked(assessment.id);
      if (response) {
        setIsLocking(false)
        history.push(`/itil/assessments/${assessment.id}/summary`);
        return;
      }
      setIsLocking(false)
    },
    style: ButtonStyle.PRIMARY,
    size: ButtonSize.SMALL,
    isLoading: isLocking,
  }
  const viewResultsButton = (
    <Button
      onClick={() => {
        storeCompanyId(organizationId);
        history.push(`/assessments/${assessment.id}/result/itil`);
      }}
      label="View"
      style={ButtonStyle.PRIMARY}
      size={ButtonSize.SMALL}
    />
  );
  const viewReviewButton = (
    <Button
      label="View"
      onClick={async () => {
        setIsLocking(true);
        const response = await handleIsLocked(assessment.id);
        if (response) {
          storeCompanyId(organizationId);
          setIsLocking(false);
          history.push(`/assessments/${assessment.id}/review/itil`);
          return;
        }
        setIsLocking(false);
      }}
      style={ButtonStyle.PRIMARY}
      size={ButtonSize.SMALL}
      isLoading={isLocking}
    />
  );
  const viewSummaryButton = <Button {...viewSummaryButtonProps} label="View" />;

  const actions = () => {

    if (locked && !assessment.isArchived) {
      return (
        <LockedActions
          assessmentId={assessment.id}
          isLocking={isLocking}
          setIsLocking={setIsLocking}
          isUnlocked={isUnlocked}
        />
      );
    }
    if(assessment.isArchived){
      return (
        <>
          {assessment.status === AssessmentState.COMPLETED && viewResultsButton}
          {assessment.status === AssessmentState.DRAFT && viewReviewButton}
          {assessment.status === AssessmentState.ACTIVE && viewSummaryButton}
          <ArchiveButton
            assessment={assessment}
            type={AssessmentFormat.ITIL}
            onChangeArchive={onChangeArchive}
          />
        </>
      )
    }
    if (assessment.status === AssessmentState.COMPLETED) {
      return (
        <>
          {viewResultsButton}
          {!assessment.endorsed && !isCerfified && (
            <div className={styles.assessment__actionsCerfify}>
              <div className={styles.tooltip}>
                <CertifyIcon
                  onClick={() => handleCertifyAssessment(assessment.id)}
                />
                <span>Request independent validation</span>
              </div>
            </div>
          )}
          <ArchiveButton
            assessment={assessment}
            type={AssessmentFormat.ITIL}
            onChangeArchive={onChangeArchive}
          />
        </>
      );
    }
    return (
      <>
      {assessment.status === AssessmentState.ACTIVE && (
        <>
          <Button {...viewSummaryButtonProps} label="Continue" />
          {!assessment.endorsed && (
            <div className={styles.assessment__actionsCerfify}>
              <div className={styles.tooltip}>
                <CertifyIcon
                  onClick={() =>
                    handleAssessmentProfile(assessment.id, "ITIL")
                  }
                />
                <span>Request independent validation</span>
              </div>
            </div>
          )}
        </>
      )}
      {assessment.status === AssessmentState.DRAFT &&
        !!assessment.capabilities.length && (
          <>
            <Button
              onClick={async () => {
                setIsLocking(true)
                const response = await handleIsLocked(assessment.id);
                if (response) {
                  storeCompanyId(organizationId);
                  setIsLocking(false)
                  history.push(
                    `/itil/assessments/${assessment.id}/questions/${assessment.capabilities[0].code}/`
                  );
                  return;
                }
                setIsLocking(false)
              }}
              label="Start"
              style={ButtonStyle.PRIMARY}
              size={ButtonSize.SMALL}
            />
            {!assessment.endorsed && (
              <div className={styles.assessment__actionsCerfify}>
                <div className={styles.tooltip}>
                  <CertifyIcon
                    onClick={() => handleAssessmentProfile(assessment.id)}
                  />
                  <span>Request independent validation</span>
                </div>
              </div>
            )}
          </>
        )}
      <ArchiveButton
        assessment={assessment}
        type={AssessmentFormat.ITIL}
        onChangeArchive={onChangeArchive}
      />
      {assessment.status !== AssessmentState.COMPLETED && (
          <div
            className={[
              styles.assessment__actionsSettings,
              styles.assessment__dropOut,
            ].join(" ")}
          >
            <DropOutMenu>
              <DropOutItem
                onClick={() => handleAssessmentProfile(assessment.id, "ITIL")}
              >
                Edit profile set up
              </DropOutItem>
              <DropOutItem
                onClick={() =>
                  onChangeArchive(assessment, AssessmentFormat.ITIL)
                }
              >
                Archive
              </DropOutItem>
            </DropOutMenu>
          </div>
        )}
        </>
    );
  };

  const progress = () => {
    if(assessment.isArchived){
      return (
        <td className={styles.assessment__perspectives}>
          <p>{assessment.status}</p>
        </td>
      );
    }
    if (assessment.status !== AssessmentState.CREATED ) {
      return (
      <td className={`${styles.assessment__perspectives} ${styles.itil}`}>
        <div className={styles.progressBar}>
          <span>
            {capabilityCompletedNumber}/{capabilityTotalNumber} practices
            complete
          </span>
          <div>
            <ProgressBar
                className={styles.tag_done}
                progress={(capabilityCompletedNumber / capabilityTotalNumber) * 100}
            />
            <ProgressBar
                className={styles.tag_warning}
                progress={((capabilityProgressNumber + capabilityCompletedNumber) / capabilityTotalNumber) * 100}
            />
          </div>
        </div>
        {assessment.selectedScope !== "Capability" && (
          <div className={styles.progressBar}>
            <span>{maturityCompleteNumber}/{maturityTotalNumber} components complete</span>
            <div>
              <ProgressBar
                className={styles.tag_done}
                progress={(maturityCompleteNumber / maturityTotalNumber) * 100}
              />
              <ProgressBar
                className={styles.tag_warning}
                progress={(maturityProgressNumber / maturityTotalNumber) * 100}
              />
            </div>
          </div>
        )}
      </td>
      )
    }
    return <td className={styles.assessment__perspectives}></td>;
  }

  const rowDates = () => {
    switch(assessment.status){
      case AssessmentState.COMPLETED:
        return (<>
            <span>{convertDate(assessment.dateStarted, true)}</span>
            <span> &#x2f; {convertDate(assessment.dateCompleted)}</span>
          </>);
      case AssessmentState.DRAFT:
      case AssessmentState.CREATED:
        return (<>
          { assessment.isArchived && <span>NA</span>}
        </>)
      default:
        return (<>
          <span>{convertDate(assessment.dateStarted)}</span>
          { assessment.isArchived && <span> &#x2f; NA</span>}
        </>)
    }
  }

  return (
    <tr className={styles.assessment}>
      <td>{assessment?.id}</td>
      <td className={styles.assessment__name}>
        <div>
          {(assessment.endorsed || isCerfified) && <CertifiedIcon />}
          <h3>{handleAssessmentName()}</h3>
        </div>
        <span>{assessment.selectedScope}</span>
        <p>
          Created by <span>{assessment?.createdBy}</span>
        </p>
      </td>
      {progress()}
      <td className={styles.assessment__startDate}>
        {rowDates()}
      </td>
      <td className={styles.assessment__actions}>
        <div>{actions()}</div>
      </td>
    </tr>
  );
};

export default PanelRowItil;
