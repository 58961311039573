import React from "react";
import { PerspectiveType } from "modules/api/endpoints/perspectivesGet";
import { tabOptions } from "../types";
import styles from "../styles.module.scss";

type PanelTableProps = {
  children?: React.ReactNode;
  tableHead?: string[];
  perspectivesData?: PerspectiveType[];
  activeTab: tabOptions;
  type: string;
};

const PanelTable: React.FunctionComponent<PanelTableProps> = ({
  children,
  perspectivesData,
  activeTab,
  type,
}) => {
  const progressColumnHeader = () => {
    if (activeTab === tabOptions.Archive) {
      return "Status";
    }
    if (type === "P3M3") {
      return (
        <div>
          {perspectivesData &&
            perspectivesData.map((p) => {
              return (
                <div key={p.code} className={styles.tooltip}>
                  <h4>{p.code}</h4>
                  <span>{p.name}</span>
                </div>
              );
            })}
        </div>
      );
    }
    return "Progress";
  };

  return (
    <table className={styles.panel__assessments}>
      <thead>
        <tr>
          <th scope="col">ID</th>
          <th scope="col">
            <h4>{type}</h4>
          </th>
          <th scope="col">{progressColumnHeader()}</th>
          <th scope="col">
            Start Date
            {(activeTab === tabOptions.Completed ||
              activeTab === tabOptions.Archive) &&
              " / End Date"}
          </th>
          <th
            scope="col"
            style={{ position: "absolute", top: "240px", right: "114px " }}
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};

export default React.memo(PanelTable);
