import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as CertifyIcon } from "modules/theme/icons/icon-certified.svg";
import { ReactComponent as CertifiedIcon } from "modules/theme/icons/icon-certified-green.svg";
import { convertDate } from "modules/utils/convertDate";
import { storeCompanyId } from "modules/utils/storeCompanyId";
import { useModal } from "modules/hooks/modal";
import Button, { ButtonSize, ButtonStyle } from "components/Button";
import ArchiveButton from "./ArchiveButton";

import Status from "./Status";
import { Assessment, AssessmentFormat, AssessmentState } from "../types";
import { PerspectiveType } from "modules/api/endpoints/perspectivesGet";

import styles from "../styles.module.scss";
import { DropOutMenu, DropOutItem } from "components/DropOutMenu";
import LockedActions from "./LockedActions";

const TotalPerspectivesNumber = 7;

type PanelRowProps = {
  assessment: Assessment;
  perspectivesData: PerspectiveType[];
  organizationId: number;
  handleAssessmentProfile: (id: number) => void;
  handleIsLocked: (id: number) => Promise<boolean>;
  isUnlocked: (id: number) => Promise<boolean>;
  locked: boolean;
  onChangeArchive: (theAssessment: Assessment, type: AssessmentFormat) => void;
};
const PanelRow: React.FunctionComponent<PanelRowProps> = ({
  assessment,
  perspectivesData,
  organizationId,
  handleAssessmentProfile,
  handleIsLocked,
  isUnlocked,
  locked,
  onChangeArchive,
}) => {
  const [isCertified, setIsCertified] = useState(false);
  const [isLocking, setIsLocking] = useState(false);
  const history = useHistory();
  const certifyConfirmation = useModal();

  const handleCertifyAssessment = async (assessmentId: number) => {
    try {
      const response = await certifyConfirmation({
        type: "confirm",
        catchOnCancel: true,
        title: "Are you sure you wish to certify this assessment?",
        description:
          "A request for certification has been sent out and you will receive the response shortly",
        assessmentId: assessmentId,
        buttonLabel: "Certify Assessment",
        hasCloseButton: true,
      }).then(() => true);

      if (response) setIsCertified(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };

  const handleAssessmentName = () => {
    return assessment.name ? assessment.name : "Not provided";
  };

  const viewResultsButton = (
    <Button
      onClick={() => {
        storeCompanyId(organizationId);
        history.push(`/assessments/${assessment.id}/result`);
      }}
      label="View"
      style={ButtonStyle.PRIMARY}
      size={ButtonSize.SMALL}
    />
  );
  const viewReviewButton = (
    <Button
      label="View"
      onClick={async () => {
        setIsLocking(true);
        const response = await handleIsLocked(assessment.id);
        if (response) {
          storeCompanyId(organizationId);
          setIsLocking(false);
          history.push(`/assessments/${assessment.id}/review`);
          return;
        }
        setIsLocking(false);
      }}
      style={ButtonStyle.PRIMARY}
      size={ButtonSize.SMALL}
      isLoading={isLocking}
    />
  );
  const viewSummaryButtonProps = {
    onClick: async () => {
      setIsLocking(true);
      const response = await handleIsLocked(assessment.id);
      if (response) {
        storeCompanyId(organizationId);
        setIsLocking(false);
        history.push(`/assessments/${assessment.id}/summary`);
        return;
      }
      setIsLocking(false);
    },
    style: ButtonStyle.PRIMARY,
    size: ButtonSize.SMALL,
    isLoading: isLocking,
  };

  const viewSummaryButton = <Button {...viewSummaryButtonProps} label="View" />;

  const actions = () => {
    if (locked && !assessment.isArchived) {
      return (
        <LockedActions
          assessmentId={assessment.id}
          isLocking={isLocking}
          setIsLocking={setIsLocking}
          isUnlocked={isUnlocked}
        />
      );
    }
    if (assessment.isArchived) {
      return (
        <>
          {assessment.status === AssessmentState.COMPLETED && viewResultsButton}
          {assessment.status === AssessmentState.ACTIVE && viewSummaryButton}
          {assessment.status === AssessmentState.DRAFT && viewReviewButton}
          {/* <ArchiveButton
            assessment={assessment}
            type={AssessmentFormat.P3M3}
            onChangeArchive={onChangeArchive}
          /> */}
        </>
      );
    }
    if (assessment.status === AssessmentState.COMPLETED) {
      return (
        <>
          {viewResultsButton}
          {/* {!assessment.certified &&
            !isCertified &&
            assessment.perspectives.length === TotalPerspectivesNumber && (
              <div className={styles.assessment__actionsCerfify}>
                <div className={styles.tooltip}>
                  <CertifyIcon
                    onClick={() => handleCertifyAssessment(assessment.id)}
                  />
                  <span>Certify assessment</span>
                </div>
              </div>
            )} */}
          {/* <ArchiveButton
            assessment={assessment}
            type={AssessmentFormat.P3M3}
            onChangeArchive={onChangeArchive}
          /> */}
        </>
      );
    }
    return (
      <>
        {/* {assessment.status === AssessmentState.ACTIVE && (
          <>
            <Button {...viewSummaryButtonProps} label={"Continue"} />
            {!assessment.certified && (
              <div className={styles.assessment__actionsCerfify}>
                <div className={styles.tooltip}>
                  <CertifyIcon
                    onClick={() => handleAssessmentProfile(assessment.id)}
                  />
                  <span>Certify assessment</span>
                </div>
              </div>
            )}
          </>
        )} */}
        {assessment.status === AssessmentState.DRAFT &&
          !!assessment.perspectives.length && (
            <>
              {/* <Button
                onClick={async () => {
                  setIsLocking(true);
                  const response = await handleIsLocked(assessment.id);
                  if (response) {
                    storeCompanyId(organizationId);
                    setIsLocking(false);
                    history.push(
                      `/assessments/${assessment.id}/questions/${assessment.perspectives[0].code}/${assessment.threads[0].code}`
                    );
                    return;
                  }
                  setIsLocking(false);
                }}
                label="Start"
                style={ButtonStyle.PRIMARY}
                size={ButtonSize.SMALL}
                isLoading={isLocking}
              /> */}
              {/* {!assessment.certified && (
                <div className={styles.assessment__actionsCerfify}>
                  <div className={styles.tooltip}>
                    <CertifyIcon
                      onClick={() => handleAssessmentProfile(assessment.id)}
                    />
                    <span>Certify assessment</span>
                  </div>
                </div>
              )} */}
            </>
          )}

        {assessment.status !== AssessmentState.COMPLETED && (
          <div
            className={[
              styles.assessment__actionsSettings,
              styles.assessment__dropOut,
            ].join(" ")}
          >
            <DropOutMenu>
              {/* <DropOutItem
                onClick={() => handleAssessmentProfile(assessment.id)}
              >
                Edit profile set up
              </DropOutItem> */}
              {/* <DropOutItem
                onClick={() =>
                  onChangeArchive(assessment, AssessmentFormat.P3M3)
                }
              >
                Archive
              </DropOutItem> */}
            </DropOutMenu>
          </div>
        )}
      </>
    );
  };

  const rowDates = () => {
    switch (assessment.status) {
      case AssessmentState.COMPLETED:
        return (
          <>
            <span>{convertDate(assessment.dateStarted, true)}</span>
            <span> &#x2f; {convertDate(assessment.dateCompleted)}</span>
          </>
        );
      case AssessmentState.CREATED:
      case AssessmentState.DRAFT:
        return <>{assessment.isArchived && <span>NA</span>}</>;
      default:
        return (
          <>
            <span>{convertDate(assessment.dateStarted)}</span>
            {assessment.isArchived && <span> &#x2f; NA</span>}
          </>
        );
    }
  };

  return (
    <tr className={styles.assessment}>
      <td className={styles.assessment__name}>
        <div>
          {(assessment.certified || isCertified) && <CertifiedIcon />}
          <h3>{handleAssessmentName()}</h3>
        </div>
        <span>{assessment.selectedScope}</span>
        <p>
          Created by <span>{assessment?.createdBy}</span>
        </p>
      </td>
      <td className={styles.assessment__perspectives}>
        {assessment.status !== AssessmentState.CREATED &&
        assessment.isArchived ? (
          <p>{assessment.status}</p>
        ) : (
          <Status perspectives={perspectivesData} assessment={assessment} />
        )}
      </td>
      <td className={styles.assessment__startDate}>{rowDates()}</td>
      <td className={styles.assessment__actions}>
        <div>{actions()}</div>
      </td>
    </tr>
  );
};

export default PanelRow;
