import React from "react";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";

import Button, { ButtonSize, ButtonStyle, ButtonType } from "components/Button";
import { ReactComponent as LogoSVG } from "modules/theme/images/mm_logo.svg";
import { ReactComponent as LogoSmallSVG } from "modules/theme/icons/logo-small.svg";
import { ReactComponent as ExitSVG } from "modules/theme/icons/buttons/exit.svg";

import { ReactComponent as PlusIcon } from "modules/theme/icons/icon-plus.svg";
import { ReactComponent as TickIcon } from "modules/theme/icons/icon-tick.svg";
import { ReactComponent as HouseIcon } from "modules/theme/icons/icon-house.svg";

import { useAuth } from "modules/hooks/auth";
import UseLockAssessment from "routes/questions/hooks/useLockAssessment";

import { ROUTE } from "modules/router/constants";

import styles from "./styles.module.scss";

type NavigationProps = {
  children?: React.ReactNode;
};

const Navigation: React.FunctionComponent<NavigationProps> = () => {
  const getAssessmentId = (path: string) => {
    if (!path) return null;

    const r = /\d+/;

    if (path.includes("assessments/")) {
      const id = path.match(r);
      return id ? Number(id[0]) : null;
    }

    return null;
  };

  const { user, logout } = useAuth();
  const allAssessmentsAccessAllowedEmailAddress = [];
  allAssessmentsAccessAllowedEmailAddress.push(
    "dmitry.isaychenko@peoplecert.org",
    "ashley.bassett@peoplecert.org",
    "narinder.dhaliwal@peoplecert.org",
    "szymon.pawlowski@peoplecert.org",
    "adam.griffith@peoplecert.org",
    "testmigrate5@mailinator.com",
    "roman.zhuravlev@peoplecert.org"
  );
  const history = useHistory();

  return (
    <nav className={styles.navigation}>
      <LogoSVG className={styles.navLogo} />
      <LogoSmallSVG className={styles.navLogoSmall} />
      <ul>
        <li>
          <NavLink exact to={ROUTE.DASHBOARD} activeClassName={styles.isActive}>
            <span className={styles.icon}>
              <HouseIcon />
            </span>
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            to={ROUTE.ORGANIZATION}
            activeClassName={styles.isActive}
          >
            <span className={styles.icon}>
              <PlusIcon />
            </span>
            Create new organization
          </NavLink>
        </li>
        {allAssessmentsAccessAllowedEmailAddress.includes(
          user?.email.toLocaleLowerCase()
        ) && (
          <li>
            <NavLink exact to={ROUTE.READONLYASSESSMENTS}>
              <span className={styles.icon}>
                <TickIcon />
              </span>
              All Assessments
            </NavLink>
          </li>
        )}
      </ul>

      <div className={styles["logout-wrapper"]}>
        <Button
          label="Log out"
          type={ButtonType.BUTTON}
          size={ButtonSize.INLINE}
          style={ButtonStyle.NEGATIVE}
          onClick={async () => {
            const id = getAssessmentId(history.location.pathname);
            if (id) {
              const handleUnlockAssessment = UseLockAssessment("/logout", id);
              await handleUnlockAssessment();
            }
            window.location.assign(logout());
          }}
        >
          <ExitSVG />
        </Button>
      </div>
    </nav>
  );
};

export default Navigation;
