import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { api, API } from "modules/api";
import { GetOrganizationsResponse } from "modules/api/endpoints/organizationsGet";
import { OrganizationType } from "modules/api/endpoints/organizationGet";
import {
  PerspectiveType,
  GetPerspectivesResponse,
} from "modules/api/endpoints/perspectivesGet";

import { useAuth } from "modules/hooks/auth";
import { useModal } from "modules/hooks/modal";

import Template from "./Template";
import MetaTitle from "components/MetaTitle";
import { boolean } from "yup";

const Route: React.FunctionComponent = () => {
  const [organizations, setOrganizations] = useState<OrganizationType[]>([]);
  const [perspectives, setPerspectives] = useState<PerspectiveType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isReadOnlyAssessments, setIsReadOnlyAssessments] = useState(false);

  const { user, logout, roles } = useAuth();

  const dialog = useModal();
  const history = useHistory();

  const handleHeadlingTitle = useCallback(() => {
    return `Welcome, ${user.firstName ? user.firstName : "User"}`;
  }, [user]);

  useEffect(() => {
    console.log("hitted");
    let isCancelled = false;

    if (window.location.href.includes("readonlyassessments")) {
      setIsReadOnlyAssessments(true);
    } else {
      setIsReadOnlyAssessments(false);
    }

    const fetchOrganizations = async () => {
      try {
        const [organizationsData, perspectivesData] = await Promise.all([
          await api(API.GET_ORGANIZATIONS()).then(
            (organizationsResponse: GetOrganizationsResponse) =>
              organizationsResponse.data
          ),
          api(API.GET_PERSPECTIVES()).then(
            (res: GetPerspectivesResponse) => res.data
          ),
        ]);
        console.log("organizationsData", organizationsData);
        if (!organizationsData.myOrgnizations.length === !isCancelled) {
          history.push("/organization");
          return;
        }
        setPerspectives(perspectivesData);
        // setOrganizations(organizationsData.myOrgnizations);
        setOrganizations(organizationsData.otherUserOrgnizations);
        setIsLoading(false);
      } catch (error) {
        await dialog({
          type: "error",
          title: "Sorry, your credentials has expired.",
          description: "Please try to login again...",
          catchOnCancel: true,
        }).finally(() => window.location.assign(logout()));
        setIsLoading(false);
      }
    };

    fetchOrganizations();

    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <React.Fragment>
      <MetaTitle title={"Home | Axelos Maturity Assessment Tool"} />
      <Template
        isLoading={isLoading}
        organizations={organizations}
        perspectives={perspectives}
        handleHeadlingTitle={handleHeadlingTitle}
        roles={roles}
      />
      {/* {otherUserOrganizations?.length > 0 && isReadOnlyAssessments && (
        <Template
          isLoading={isLoading}
          organizations={otherUserOrganizations}
          perspectives={perspectives}
          handleHeadlingTitle={handleHeadlingTitle}
          roles={roles}
        />
      )} */}
    </React.Fragment>
  );
};

export default Route;
